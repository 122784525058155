// src/ChoiceChip.js

import React from "react";
import "./ChoiceChip.css";
const ChoiceChip = ({ label, selected }) => {
  return (
    <div className={`choice-chip selected`}>{label}</div>
  );
};

export default ChoiceChip;
