export function setOgTags(title, description, image, url) {
    // Create or update OG title tag
    updateMetaTag('og:title', title);
    
    // Create or update OG description tag
    updateMetaTag('og:description', description);
    
    // Create or update OG image tag
    updateMetaTag('og:image', image);
    
    // Create or update OG URL tag
    updateMetaTag('og:url', url);
  }

  
  function updateMetaTag(property, content) {
    let metaTag = document.querySelector(`meta[property="${property}"]`);
  
    if (!metaTag) {
      // Create a new meta tag if it doesn't exist
      metaTag = document.createElement('meta');
      metaTag.setAttribute('property', property);
      document.head.appendChild(metaTag);
    }
  
    // Update the content
    metaTag.setAttribute('content', content);
  }
  