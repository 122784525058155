import React from "react";
import Navbar from "../../Widgets/Navbar/Navbar";
import Footer from "../../Widgets/Footer/Footer";
import styles from "./ATO.module.css";
import { Helmet } from "react-helmet";

const ATO = () => {
  return (
    <div className={styles.page}>
      <Navbar />
      <div style={{ height: "60px" }} />

      <Helmet>
        <title>
          Zerodha new Feature - Alert triggered order ATO | A complete guide
        </title>
        <meta
          name="keywords"
          content="Zerodha new Feature, zerodha alert, zerodha alert trigger order,जीरोधा का अलर्ट ट्रिगर्स ऑर्डर"
        />
        <meta
          name="description"
          content="Maximize your trading potential with Zerodha's Alert Trigger Order. Get real-time alerts and execute trades automatically for smarter investing."
        />
        <link href="https://www.lucknowlions.com/ato" rel="canonical" />
        <meta name="robots" content="index" />
      </Helmet>

      <div className={styles.main}>
        <div className={styles.hed1}>
          <h1>जीरोधा का अलर्ट ट्रिगर्स ऑर्डर (ATO) फीचर</h1>
          <h2>(एक विस्तृत गाइड)</h2>
        </div>

        <div className={styles.body}>
          <div className={styles.qes}>परिचय </div>
          <p>
            जीरोधा, भारत का अग्रणी डिस्काउंट ब्रोकर, जिसने हाल ही में एक नया और
            एडवांस फीचर पेश किया है जिसे अलर्ट ट्रिगर्स ऑर्डर (ATO) कहा जाता है।
            यह फीचर ट्रेडर्स को अधिक कुशलता से व्यापार (ट्रेड) करने में मदद करता
            है। इस गाइड में, हम ATO के बारे में विस्तार से जानेंगे और इसका उपयोग
            करने के तरीके समझेंगे।
          </p>
          <iframe
            className={styles.yt_vd}
            width="616"
            height="347"
            src="https://www.youtube.com/embed/0XMSKV-ena4?si=N_KD5YngGsvzjDlo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <div className={styles.qes}>अलर्ट क्या है? </div>
          <p>
            अलर्ट एक सरल सुविधा है जो आपको किसी स्टॉक के मूल्य में परिवर्तन के
            बारे में सूचित (notify) करती है। यह एक प्रकार का अनुस्मारक(reminder)
            है जो आपको बताता है कि आपके द्वारा निर्धारित कोई विशेष स्थिति पूरी
            हो गई है।
          </p>
          <p>
            उदाहरण के लिए:
            <ul>
              <li>
                यदि आज अगर RVNL का स्टॉक ₹540 है, और आप चाहते हैं कि जब RVNL का
                स्टॉक ₹400 तक पहुंचे तो आपको सूचित किया जाए।{" "}
              </li>
            </ul>
            इस स्थिति में, आप एक अलर्ट सेट कर सकते हैं। जब ये शर्तें पूरी होंगी,
            आपको एक नोटिफिकेशन मिलेगा।
          </p>
          <div className={styles.qes}>अलर्ट ट्रिगर्स ऑर्डर (ATO) क्या है?</div>
          <p>
            ATO एक नया एडवांस सुविधा है जो अलर्ट के कॉन्सेप्ट को एक कदम आगे ले
            जाती है। यह न केवल आपको सूचित करती है, बल्कि आपके लिए एक ऑर्डर भी
            प्लेस कर देती है।
          </p>
          <p>
            यह कैसे काम करता है:
            <ol>
              <li>आप एक अलर्ट सेट करते हैं (जैसे, जब RVNL ₹400 तक पहुंचे)। </li>
              <li>
                साथ ही, आप एक ऑर्डर भी सेट करते हैं (जैसे, 100 शेयर खरीदने का
                ऑर्डर)।
              </li>
              <li>
                जब अलर्ट की शर्त पूरी होती है, सिस्टम स्वचालित रूप से आपका ऑर्डर
                प्लेस कर देता है।
              </li>
            </ol>
          </p>
          <p>
            यह सुविधा विशेष रूप से उपयोगी है जब आप लगातार बाजार की निगरानी नहीं
            कर सकते, या जब आप किसी विशेष मूल्य स्तर पर ट्रेड करना चाहते हैं।
          </p>
          <div className={styles.qes}>ATO का विस्तृत उपयोग</div>
          <ol>
            <li>अलर्ट सेट करना:</li>
            <ol type="a">
              <li>जीरोधा Kite प्लेटफॉर्म पर लॉग इन करें। </li>
              <li>
                जिस स्टॉक पर अलर्ट लगाना है उसे चुने फिर 'मोर' ऑप्शन पर क्लिक
                करें।
              </li>
              <li>
                अलर्ट के विवरण भरें:
                <ul type="disc">
                  <li>अलर्ट का नाम (जैसे "RVNL ₹400") </li>
                  <li>
                    फिर डेटा पॉइंट चुने कि आपको किस प्रकार का अलर्ट चाहिए जैसे
                    हमने चुना ( Last price )
                  </li>
                  <li>फिर स्टॉक का सिंबल और एक्सचेंज (जैसे RVNL)</li>
                  <li>अब गणित की कंडीशन (जैसे "लास्ट प्राइस &lt;= ₹400") </li>
                  <li>अलर्ट का प्रकार (सिर्फ अलर्ट या अलर्ट ट्रिगर्स ऑर्डर)</li>
                </ul>
              </li>
            </ol>
            <li>
              ऑर्डर सेट करना:
              <ol type="a">
                <li>
                  अलर्ट सेट करने के तुरंत बाद, उसी स्क्रीन पर ऑर्डर डिटेल्स
                  भरें।
                </li>
                <li>
                  सर्च बॉक्स में उस स्टॉक का नाम लिखें जिसका आपको ATO लगाना है{" "}
                </li>
                <li>
                  चुनें:
                  <ul type="disc">
                    <li>ऑर्डर का प्रकार (खरीद या बिक्री)</li>
                    <li>क्वांटिटी (कितने शेयर)</li>
                    <li>प्राइस (लिमिट ऑर्डर के लिए) या मार्केट ऑर्डर</li>
                    <li>वैधता (दिन या IOC)</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              बास्केट में जोड़ना:
              <ol type="a">
                <li>सभी ऑर्डर जोड़ने के बाद, 'Create' बटन पर क्लिक करें। </li>
                <li>आपका ATO सेट हो गया है! </li>
              </ol>
            </li>
            <li>
              अलर्ट क्रिएट करना:
              <ol type="a">
                <li>सभी ऑर्डर जोड़ने के बाद, 'Create' बटन पर क्लिक करें। </li>
                <li>आपका ATO सेट हो गया है!</li>
              </ol>
            </li>
          </ol>
          <div className={styles.qes}>विशेष फीचर्स और टिप्स </div>
          <ol>
            <li>
              <b>मल्टीपल स्टॉक्स</b>: एक ही ATO में कई स्टॉक्स के लिए अलर्ट और
              ऑर्डर सेट कर सकते हैं।
            </li>
            <li>
              <b>प्राइस कंडीशंस: </b>
              <ul>
                <li>
                  "लेस दैन या इक्वल टू" (&lt;=): जब आप खरीदना चाहते हैं और स्टॉक
                  का मूल्य गिरने की उम्मीद कर रहे हैं।
                </li>
                <li>
                  "ग्रेटर दैन या इक्वल टू" (&gt;=): जब आप बेचना चाहते हैं और
                  स्टॉक का मूल्य बढ़ने की उम्मीद कर रहे हैं।
                </li>
              </ul>

              <li>
                <b>परसेंटेज चेंज:</b>मूल्य के अलावा, आप प्रतिशत परिवर्तन पर भी
                अलर्ट सेट कर सकते हैं।
              </li>
              <li>
                <b>ऑप्शंस ट्रेडिंग:</b>ATO का उपयोग ऑप्शंस के लिए भी किया जा
                सकता है। ध्यान रखें कि ऑप्शंस ट्रेडिंग के लिए अधिक मार्जिन की
                आवश्यकता होती है।
              </li>
              <li>
                <b>मॉडिफिकेशन: </b>: सेट किए गए ATO को बाद में 'Alerts' सेक्शन
                में जाकर मॉडिफाई किया जा सकता है।
              </li>
            </li>
          </ol>
          <div className={styles.qes}>ATO के लाभ </div>
          <ol>
            <li>
              <b>स्वचालित व्यापार: </b> आपकी अनुपस्थिति में भी ट्रेड होता है,
              जिससे आप कोई अवसर नहीं चूकते।
            </li>
            <li>
              <b>समय की बचत</b>लगातार बाजार की निगरानी करने की आवश्यकता नहीं, जो
              व्यस्त पेशेवरों के लिए बहुत उपयोगी है।
            </li>
            <li>
              <b>भावनाओं से मुक्त व्यापार:</b>पूर्व-निर्धारित शर्तों पर आधारित
              निर्णय, जो भावनात्मक ट्रेडिंग को कम करता है।
            </li>
            <li>
              <b>बहु-स्टॉक प्रबंधन:</b>एक साथ कई स्टॉक्स के लिए अलर्ट और ऑर्डर
              सेट कर सकते हैं, जो पोर्टफोलियो प्रबंधन को आसान बनाता है।
            </li>
            <li>
              <b>लचीलापन: </b> विभिन्न प्रकार के ऑर्डर (लिमिट, मार्केट) और
              कंडीशंस सेट कर सकते हैं।
            </li>
          </ol>
          <div className={styles.qes}>सावधानियां और महत्वपूर्ण बातें </div>
          <ol>
            <li>
              <b>सही मूल्य और शर्तें: </b> सुनिश्चित करें कि आपने सही मूल्य और
              शर्तें सेट की हैं। एक छोटी सी गलती बड़े नुकसान का कारण बन सकती है।
            </li>
            <li>
              <b>नियमित समीक्षा</b>अपने सेट किए गए ATO की नियमित रूप से समीक्षा
              करें और बाजार की स्थितियों के अनुसार उन्हें अपडेट करें।
            </li>

            <li>
              <b>मार्जिन आवश्यकताएं</b>विशेष रूप से ऑप्शंस ट्रेडिंग के लिए,
              सुनिश्चित करें कि आपके पास पर्याप्त मार्जिन उपलब्ध है।
            </li>
            <li>
              <b>नेटवर्क और सिस्टम विश्वसनीयता</b>ATO आपके सिस्टम और इंटरनेट
              कनेक्शन पर निर्भर करता है। सुनिश्चित करें कि आपके पास एक विश्वसनीय
              सेटअप है।
            </li>
            <li>
              <b>बाजार की अस्थिरता: </b> अत्यधिक अस्थिर बाजार में, ATO
              अप्रत्याशित परिणाम दे सकता है। ऐसी स्थितियों में सावधानी बरतें।
            </li>
          </ol>
          <div className={styles.qes}>निष्कर्ष</div>
          <p>
            जीरोधा का ATO फीचर ट्रेडर्स के लिए एक शक्तिशाली उपकरण है। यह आपको
            अधिक कुशलता से व्यापार करने और बाजार के अवसरों का लाभ उठाने में मदद
            कर सकता है। यह विशेष रूप से उन लोगों के लिए उपयोगी है जो पूर्णकालिक
            ट्रेडर नहीं हैं या जो अपने व्यापार को अधिक व्यवस्थित और कम भावनात्मक
            बनाना चाहते हैं। हालांकि, जैसे किसी भी ट्रेडिंग उपकरण के साथ, ATO का
            उपयोग सावधानी और समझ के साथ किया जाना चाहिए। अपने जोखिम प्रबंधन के
            सिद्धांतों का पालन करना न भूलें और हमेशा अपनी वित्तीय स्थिति के
            अनुरूप ट्रेड करें। ATO के साथ, आप अपने ट्रेडिंग को अगले स्तर पर ले
            जा सकते हैं। इसे आजमाएं और देखें कि यह आपकी ट्रेडिंग रणनीति को कैसे
            बेहतर बना सकता है!
          </p>
          <br />
          <span className={styles.qes}>ध्यान दें : </span>अभी यह सुविधा सिर्फ
          काइट वेब बेस एप्लीकेशन पर उपलब्ध है काइट मोबाइल पर जल्द ही यह सुविधा
          लायी जायेगी।
        </div>
        <div style={{ height: "120px" }} />
      </div>
      <Footer />
    </div>
  );
};

export default ATO;
