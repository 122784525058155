import React from "react";
import styles from "./NotFound.module.css";
import { Link } from "react-router-dom";
import Navbar from "../../Widgets/Navbar/Navbar";

const NotFound = () => {
  return (
    <div className={styles.notFoundContainer}>
      <Navbar />
      <h1>404</h1>
      <h2>Oops! Page Not Found</h2>
      <p>The page you're looking for doesn't exist.</p>

      <Link to="/">
        <button>Go to Home</button>
      </Link>
    </div>
  );
};

export default NotFound;
