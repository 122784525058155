import React from "react";
import Navbar from "../../../Widgets/Navbar/Navbar";
import Footer from "../../../Widgets/Footer/Footer";
import styles from "./Interest.module.css";

const Lko_to_Banglore = () => {
  return (
    <div className={styles.page}>
      <div style={{ height: "60px" }} />
      <Navbar />

      <div className={styles.main}>
        <div className={styles.hed1}>
          How to reach zerodha head office in bangalore
        </div>

        <div className={styles.body}>
          <p>
            दोस्तों जैसा कि आप जानते हैं भारत का सबसे बढ़िया और सस्ता डिस्काउंट
            ब्रोकर ज़ेरोढा है जहाँ पर आपको 0 ब्रोकरेज डिलीवरी में ली जाती है वैसे
            तो बैंगलोर बहुत ही अच्छा और सुन्दर शहर है लेकिन यदि आप यहां पर पहली
            बार जा रहे हैं तो आपको कुछ बातों का ध्यान रखना होगा
          </p>

          <div className={styles.qes} />

          <p>
            <h5>
              <b>फेज १ - लखनऊ तो बैंगलोर-</b>
            </h5>
            <ul>
              <li>
                सबसे पहले आपको चैधरी चरण सिंह इंटरनेशनल एयरपोर्ट लखनऊ में
                टर्मिनल 3 पर जाना होगा। (आपका कौन सा टर्मिनल है इसकी जानकारी
                आपके टिकट पार लिखी होगी)
              </li>
              <li>
                टर्मिनल 3 पर पहुचंने के बाद आपको यहां से 2nd फ्लोर पर जाना होगा,
                डोमेस्टिक फ्लाइट के लिए ।
              </li>
              <li>
                यदि आपके पास में INDIGO एयरलाइन का टिकट है तो आपको गेट संख्या 2
                में जाना होगा आपको, यहां पर आपके टिकट को चेक किया जायेगा और आपके
                पहचान पत्र को भी ।
              </li>
              <li>
                टिकट और पहचान पत्र चेक हो जाने के बाद आपको सामने बोर्डिंग काउंटर
                पर जाना होगा जहाँ पर आपके बैग को वज़न किया जायेगा और आपको सुझाव
                दिया जायेगा कि बैग में आप किन चीज़ो को ले जा सकते हैं किन चीज़ों
                को नहीं, सभी इलेक्ट्रॉनिक उपकरण (जैसे लैपटॉप, लैपटॉप और मोबाइल
                चार्जर, लीड या ईरफ़ोन इत्यादि) आपको हैंड बैग में रखने होंगे जहाँ
                पर तय कि गयी सीमा 7किलोग्राम और 1 व्यक्ति 1 ही हैंड बैग ले जा
                सकता है ।
              </li>
              <li>
                आपको ऐसी बोर्डिंग काउंटर पर फिर आपको बोर्डिंग पास दिया जायेगा और
                आपके बैग में टैग लगाकर इसको लोडिंग के लिए भेज दिया जायेगा ।
              </li>
              <li>
                अब आपको अपनी बायीं तरफ सिक्योरिटी चेक के लिए जाना होगा जहाँ आपको
                अपने बैग का सारा सामान बैग से निकल कर ट्रे में खुद ही भरकर
                ट्राली में रखना होगा जहाँ पर आपका सारा सामान साथ में आपकी भी
                चेकिंग कि जाएगी ।
              </li>
              <li>
                अब आपको गेट नंबर १५ पर जाना होगा और अपनी फ्लाइट का इंतज़ार करना
                होगा
              </li>
              <li>
                सबकुछ सही होने पर समयनुसार आपको फ्लाइट में बैठ जाना होगा अपनी
                सीट पर ध्यान दें जो आपकी सीट है उस पर ही बैठें और दिए हुए
                निर्देशों का पालन करें ।
              </li>
              <li>
                फ्लाइट आपको बैंगलोर 2 घंटे 45 मिनट में पहुंचा देगी अगर सब कुछ
                सही रहता है तो जैसे मौसम इत्यादि ।
              </li>
            </ul>
          </p>

          <div className={styles.qes} />

          <p>
            <h3>
              <b>मुबारक हो अब आप बैंगलोर एयरपोर्ट पर पहुँच गए हैं । </b>
            </h3>
            <br />
            <h5>
              <b>फेज 2- बैंगलोर एयरपोर्ट से ज़ेरोढा ऑफिस जाने का तरीका</b>
            </h5>
            <ul>
              <li>
                अब आपको अपनी फ्लाइट से उतरना है और इसी समय आपको अनाउंसमेंट सुननी
                है जिसपर बोला जायेगा कि आपका बैग किस बेल्ट नंबर पर मिलेगा
              </li>
              <li>
                आपको फ्लाइट से उतर कर अब सीधे बैग रेक्लैम सेण्टर पर जाना है और
                बताई गयी बेल्ट नंबर से अपना बैग उठाना है फिर एयरपोर्ट के बाहर आ
                जाना है।
              </li>
              <li>
                आपके सामने आपको ताज होटल भी दिखेगा, आप चाहें तो वहां भी जा सकते
                हैं ।
              </li>
              <li>
                अब आपको बाहर आ जाना है फिर दायीं तरफ जाना है बस स्टैंड पर, यह
                आपको बोलना है JP नगर जाना है बस Zerodha के ऑफिस तक नहीं जाएगी
                आपको सीधे ज़ेरोढा के ऑफिस तक जाने के लिए ओला या रपिडो करना पड़ेगा
              </li>
              <li>
                यदि आप रुकना चाहते हैं तो ज़ेरोढा ऑफिस के पास में ही कुछ ठहरने के
                स्थान हैं जहाँ आप रुक सकते हैं
                <ul>
                  <li>Comfort Zone hotel (डलमिआ सर्किल फ्लाईओवर के पास में)</li>
                  <li>4th Seasons Suites</li>
                </ul>
              </li>
              <li>अब यहां से आपको St. clrance public school के लिए जाना होगा, वैष्णवी टैरस की तरफ बढ़ते ही आपको बायीं तरफ Zerodha का ऑफिस मिल जायेगा ।</li>
            </ul>
          </p>
          </div>

                  <div style={{ height: "120px" }} />
      </div>
      <Footer />
    </div>
  );
};

export default Lko_to_Banglore;
