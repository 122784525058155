import React from "react";
import Navbar from "../../Widgets/Navbar/Navbar";

const Acc_Delete = () => {
  return (
    <React.Fragment>
      <Navbar />
      <div style={{ height: "65px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h1>To delete your Account</h1>
        <p>Please Email us on : contact@lucknowlions.com</p>
      </div>
    </React.Fragment>
  );
};

export default Acc_Delete;
